import React, { useState } from 'react';
import {
    INITAL_INTREST_RATE,
    SUBSEQUENT_INTREST_RATE
} from './utils_rates.js';
import {getMinimumCollectiveShare, calculateSoloPurchase, calculateCollective} from './utils_calculator';

const MAX_OC_OWNERSHIP = 45;
const MIN_OC_OWNERSHIP = 5;

export const getRecommendedOption = function(quoteData) {

    const minimumOCShare = getMinimumCollectiveShare(quoteData);

    if (minimumOCShare <= MIN_OC_OWNERSHIP) {
        return MIN_OC_OWNERSHIP;
    } else if(minimumOCShare < MAX_OC_OWNERSHIP) {
        return Math.round(((MAX_OC_OWNERSHIP-minimumOCShare)/2)+minimumOCShare);
    } else if (minimumOCShare >= MAX_OC_OWNERSHIP) {
        return MAX_OC_OWNERSHIP;
    }

}

export default function Options (props) {

    const handleChangeOptions = (e) => {
        const option = e.target.value;
        if(option === "Maximal ownership") {
            const minimumOCShare = getMinimumCollectiveShare(props.quoteState);
            setCoOwnedPercentage(minimumOCShare);
        } else if (option === "Lowest payment") {
            setCoOwnedPercentage(MAX_OC_OWNERSHIP);
        }
    }

    const recommendedOCShare = getRecommendedOption(props.quoteState);

    const [collectiveSupportPercentage, setcollectiveSupportPercentage] = useState(recommendedOCShare);
    const handleSetcollectiveSupportPercentage = (e) => {
        setcollectiveSupportPercentage(Number(e.target.value));
    }

    const [editCollectiveSupportPercentage, setEditCollectiveSupportPercentage] = useState(false);
    const handleSetEditCollectiveSupportPercentage = () => {
        setEditCollectiveSupportPercentage(!editCollectiveSupportPercentage);
    }

    const [coOwnedPercentage, setCoOwnedPercentage] = useState(MAX_OC_OWNERSHIP);
    const handleSetCoOwnedPercentage = (e) => {
        setCoOwnedPercentage(Number(e.target.value));
    }

    const [editCoOwnedPercentage, setEditCoOwnedPercentage] = useState(false);
    const handleSetEditCoOwnedPercentage = () => {
        setEditCoOwnedPercentage(!editCoOwnedPercentage);
    }

    const soloData = calculateSoloPurchase(props.quoteState);
    const collectiveSupport = calculateCollective(props.quoteState, collectiveSupportPercentage);
    const coOwned = calculateCollective(props.quoteState, coOwnedPercentage);

    // console.log(soloData);
    // console.log(collectiveSupport);
    return (
        <div className={`w-full lg:w-3/4 lg:mb-16`}>
            <div className="flex flex-col lg:flex-row items-start">
                <div className="w-full lg:w-1/3 rounded drop-shadow-xl bg-white p-4 lg:mr-4 mb-4 lg:mb-0">
                    <h3 className="pt-2 text-lg font-light text-ocBlue border-b border-gray">Traditional purchase</h3>
                    <div className="flex bg-ocSoftGray mt-4 justify-center w-full">
                        <div className="flex flex-col items-center justify-center mx-2 ">
                            <p className="text-4xl text-black font-bold">100<span className="text-base text-gray-400">%</span></p>
                            <p className="text-xs text-center text-gray-600 font-bold">Your ownership</p>
                        </div>
                        <div className="text-8xl text-white ml-4 my-2">/</div>
                        <div className="flex flex-col items-center mx-2 justify-center">
                            <p className="text-4xl text-black font-semibold">0<span className="text-base text-gray-400">%</span></p>
                            <p className="text-xs text-center text-gray-600 font-semibold">Collective equity</p>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-col justify-center items-center w-2/5 h-48 mt-8 border-b border-ocDarkBlue">
                            { soloData.missingCapital > 0 &&
                            <div className={`flex justify-center items-center w-4/6 h-1/5 bg-red-400 border-t-4 border-l-4 border-r-4 border-orange-200 `} style={{height : `${soloData.missingCapitalHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_solo_missing">{soloData.missingCapital}k</p>
                            </div>
                            }
                            <div className={`flex justify-center items-center w-4/6 h-3/5 bg-orange-200`} style={{height : `${soloData.bankLoanHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_solo_bankLoan">{soloData.bankLoan}k</p>
                            </div>
                            <div className={`flex justify-center items-center w-4/6 h-1/5 bg-orange-300`} style={{height : `${soloData.depositHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_solo_deposit">{soloData.deposit}k</p>
                            </div>
                            
                        </div>
                        <div className="flex flex-col justify-center w-3/5">
                            { soloData.missingCapital > 0 &&
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" data-testid="cypress_solo_legend_unavailable">
                                <span className="block w-3 h-3 bg-red-400 mr-2"/>
                                Unavailable capital based on income
                            </div>
                            }
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-orange-200 mr-2"/>
                                Bank loan
                            </div>
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-orange-300 mr-2"/>
                                Your deposit
                            </div> 
                        </div>
                    </div>
                    <div className="flex w-full">
                        { (soloData.missingCapital > 0) ?
                        <div className="flex flex-col mx-2 my-6">
                            <p className="text-4xl text-black font-bold text-red-600" data-testid="cypress_solo_monthlyPayment">£{soloData.rates.averageMonthlyPayments}</p>
                            <p className="text-sm text-gray-600 font-bold">Average monthly spend</p>
                            <p className="text-xs text-gray-400">*  {soloData.rates.initialRateMonthsCount} months of £{soloData.rates.initialMonthlyPaymentsRounded} at {(INITAL_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-gray-400">*  {soloData.rates.subsequentRateMonthsCount} months of £{soloData.rates.subsequentMonthlyPaymentsRounded} at {(SUBSEQUENT_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-red-400 w-2/3" data-testid="cypress_solo_missingIncomeInfo">Income needs to be £{soloData.missingIncome}k higher to qualify for the full loan.</p>
                        </div>
                        :
                        <div className="flex flex-col mx-2 my-6">
                            <p className="text-4xl text-black font-bold" data-testid="cypress_solo_monthlyPayment">£{soloData.rates.averageMonthlyPayments}</p>
                            <p className="text-sm text-gray-600 font-bold">Average monthly spend</p>
                            <p className="text-xs text-gray-400">*  {soloData.rates.initialRateMonthsCount} months of £{soloData.rates.initialMonthlyPaymentsRounded} at {(INITAL_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-gray-400">*  {soloData.rates.subsequentRateMonthsCount} months of £{soloData.rates.subsequentMonthlyPaymentsRounded} at {(SUBSEQUENT_INTREST_RATE*100).toFixed(2)}%</p>
                        </div>
                        }

                    </div>
                    { (soloData.missingCapital <= 0) ?
                    <div className="flex flex-row  items-center justify-end">
                        <button
                            className="inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-ocTeal transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
                        >
                            Get an OC mortgage
                            <svg
                            className="inline-block w-3 ml-2"
                            fill="currentColor"
                            viewBox="0 0 12 12"
                            >
                            <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                            </svg>
                        </button>
                    </div>
                    :
                    <div className="flex flex-row  items-center justify-end">
                        <div
                            className="inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide bg-gray-400 text-white rounded-full"
                        >
                            Mortgage unavailable ⛔
                        </div>
                    </div>
                    }
                </div>
                <div className="w-full lg:w-1/3 rounded drop-shadow-xl bg-white p-4 lg:mr-4 mb-4 lg:mb-0 outline outline-ocTeal outline-offset-2 outline-1">
                    <div className="mt-2 border-b border-t border-gray flex justify-center">
                        <h3 className="text-lg font-bold text-ocTeal">Recommend</h3>
                    </div>
                    <div className="flex bg-ocLightCyan mt-4 justify-center w-full">
                        <div className="flex flex-col items-center mx-2 justify-center">
                            <p className="text-4xl text-black font-semibold">{(100-collectiveSupportPercentage)}<span className="text-base text-gray-400">%</span></p>
                            <p className="text-xs text-center text-gray-600 font-semibold">Your ownership</p>
                        </div>
                        <div className="text-8xl text-white ml-4 my-2">/</div>
                        
                        <div className="flex flex-col items-center mx-2 justify-center">
                            {editCollectiveSupportPercentage 
                            ? <p 
                                className="text-4xl text-black font-semibold cursor-pointer"
                            >
                                <input 
                                    type="number"
                                    min={MIN_OC_OWNERSHIP}
                                    max={MAX_OC_OWNERSHIP} 
                                    value={collectiveSupportPercentage}
                                    onChange={handleSetcollectiveSupportPercentage}
                                    onBlur={handleSetEditCollectiveSupportPercentage} 
                                    className="bg-transparent text-slate-600 text-4xl font-semibold focus:outline-none focus:ring w-16" 
                                />
                                <span className="text-base text-gray-400">%</span>
                            </p>

                            : <p 
                                className="text-4xl text-black font-semibold cursor-pointer"
                                onClick={handleSetEditCollectiveSupportPercentage}
                            >
                                <span data-testid="cypress_recommended_collectivePercent">{collectiveSupportPercentage}</span>
                                <span className="text-base text-gray-400">%</span>
                            </p>
                            }
                            <p className="text-xs text-center text-gray-600 font-semibold">Collective equity</p>
                        </div>

                    </div>
                    <div className="flex flex-row" data-testid="cypress_recommended">
                        <div className="flex flex-col justify-center items-center w-2/5 h-48 mt-8 border-b border-ocDarkBlue">
                            { collectiveSupport.missingCapital > 0 &&
                            <div className="flex justify-center items-center w-4/6 h-1/6 bg-red-400 transition-height" style={{height : `${collectiveSupport.missingCapitalHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_recommended_missing">{collectiveSupport.missingCapital}k</p>
                            </div>
                            }
                            <div className="flex justify-center items-center w-4/6 h-4/5 bg-indigo-200 transition-height" style={{height : `${collectiveSupport.bankLoanHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_recommended_bankLoan">{collectiveSupport.bankLoan}k</p>
                            </div>
                            <div className="flex justify-center items-center w-4/6 h-1/5 bg-violet-300 transition-height" style={{height : `${collectiveSupport.sharedEquityHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_recommended_sharedEquity">{collectiveSupport.sharedEquityAmount}k</p>
                            </div>
                            <div className="flex justify-center items-center w-4/6 h-1/5 bg-blue-300 transition-height" style={{height : `${collectiveSupport.depositHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_recommended_deposit">{collectiveSupport.deposit}k</p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center w-3/5">
                            { collectiveSupport.missingCapital > 0 &&
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-red-400 mr-2" data-testid="cypress_recommended_legend_unavailable"/>
                                Missing capital
                            </div>
                            }
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-indigo-200 mr-2"/>
                                Bank loan
                            </div>
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-violet-300 mr-2"/>
                                Collective equity
                            </div>
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-blue-300 mr-2"/>
                                Your deposit
                            </div> 
                        </div>
                    </div>
                    <div className="flex w-full">
                        { (collectiveSupport.missingCapital > 0) ?
                         <div className="flex flex-col mx-2 my-6">
                            <p className="text-4xl text-black font-bold text-red-600" data-testid="cypress_recommended_monthlyPayment">£{collectiveSupport.rates.averageMonthlyPayments}</p>
                            <p className="text-sm text-gray-600 font-bold">Average monthly spend</p>
                            <p className="text-xs text-gray-400">*  {collectiveSupport.rates.initialRateMonthsCount} months of £{collectiveSupport.rates.initialMonthlyPaymentsRounded} at {(INITAL_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-gray-400">*  {collectiveSupport.rates.subsequentRateMonthsCount} months of £{collectiveSupport.rates.subsequentMonthlyPaymentsRounded} at {(SUBSEQUENT_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-red-400 w-2/3" data-testid="cypress_recommended_missingIncomeInfo">Your income and deposit are too low. To qualify for the full loan or you need to increase your deposit by ${collectiveSupport.missingCapital}k</p>
                         </div>
                        :
                        <div className="flex flex-col mx-2 my-6">
                            <p className="text-4xl text-black font-bold" data-testid="cypress_recommended_monthlyPayment">£{collectiveSupport.rates.averageMonthlyPayments}</p>
                            <p className="text-sm text-gray-600 font-bold">Average monthly spend*</p>
                            <p className="text-xs text-gray-400">*  {collectiveSupport.rates.initialRateMonthsCount} months of £{collectiveSupport.rates.initialMonthlyPaymentsRounded} at {(INITAL_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-gray-400">*  {collectiveSupport.rates.subsequentRateMonthsCount} months of £{collectiveSupport.rates.subsequentMonthlyPaymentsRounded} at {(SUBSEQUENT_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-gray-400 w-2/3">including max £{collectiveSupport.rates.rentPayment} monthly rental payment to equity owners</p>

                        </div>
                        }
                        
                    </div>
                    { (collectiveSupport.missingCapital <= 0) &&
                    <div className="flex flex-row  items-center justify-end">
                        <button
                            className="inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-ocTeal transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
                        >
                            Start your campaign 
                            <svg
                            className="inline-block w-3 ml-2"
                            fill="currentColor"
                            viewBox="0 0 12 12"
                            >
                            <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                            </svg>
                        </button>
                    </div>
                    }
                </div>
                <div className="w-full lg:w-1/3 rounded drop-shadow-xl bg-white p-4 ">
                    <div className="pt-2 border-b border-gray flex flex-row justify-between">
                        <h3 className="text-lg font-light text-ocBlue">Others :</h3>
                        <select 
                            className="px-2" 
                            onChange={handleChangeOptions}
                            data-testid="cypress_alternative_dropDown">
                            <option>Lowest payment</option>
                            <option>Maximal ownership</option>
                        </select>
                    </div>
                    <div className="flex bg-ocSoftGray mt-4 justify-center w-full">
                        <div className="flex flex-col items-center mx-2 justify-center">
                            <p className="text-4xl text-black font-semibold">{(100-coOwnedPercentage)}<span className="text-base text-gray-400">%</span></p>
                            <p className="text-xs text-center text-gray-600 font-semibold">Your ownership</p>
                        </div>
                        <div className="text-8xl text-white ml-4 my-2">/</div>
                        <div className="flex flex-col items-center mx-2 justify-center">
                        {editCoOwnedPercentage 
                            ? <p 
                                className="text-4xl text-black font-semibold cursor-pointer"
                            >
                                <input 
                                    type="number"
                                    min="10"
                                    max="90" 
                                    value={coOwnedPercentage}
                                    onChange={handleSetCoOwnedPercentage}
                                    onBlur={handleSetEditCoOwnedPercentage} 
                                    className="bg-transparent text-slate-600 text-4xl font-semibold focus:outline-none w-16" 
                                />
                                <span className="text-base text-gray-400">%</span>
                            </p>

                            : <p 
                                className="text-4xl text-black font-semibold cursor-pointer"
                                onClick={handleSetEditCoOwnedPercentage}
                            >
                                <span data-testid="cypress_alternative_collectivePercent">{coOwnedPercentage}</span>
                                <span className="text-base text-gray-400">%</span>
                            </p>
                            }
                            <p className="text-xs text-center text-gray-600 font-semibold">Collective equity</p>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-col justify-center items-center w-2/5 h-48 mt-8 border-b border-ocDarkBlue">
                            { coOwned.missingCapital > 0 &&
                            <div className="flex justify-center items-center w-4/6 h-1/6 bg-red-400 transition-height" style={{height : `${coOwned.missingCapitalHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_alternative_missing">{coOwned.missingCapital}k</p>
                            </div>
                            }
                            <div className="flex justify-center items-center w-4/6 h-4/5 bg-indigo-200 transition-height" style={{height : `${coOwned.bankLoanHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_alternative_bankLoan">{coOwned.bankLoan}k</p>
                            </div>
                            <div className="flex justify-center items-center w-4/6 h-1/5 bg-violet-300 transition-height" style={{height : `${coOwned.sharedEquityHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_alternative_sharedEquity">{coOwned.sharedEquityAmount}k</p>
                            </div>
                            <div className="flex justify-center items-center w-4/6 h-1/5 bg-blue-300 transition-height" style={{height : `${coOwned.depositHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold" data-testid="cypress_alternative_deposit">{coOwned.deposit}k</p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center w-3/5">
                            { coOwned.missingCapital > 0 &&
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-red-400 mr-2"/>
                                Missing capital
                            </div>
                            }
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-indigo-200 mr-2"/>
                                Bank loan
                            </div>
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-violet-300 mr-2"/>
                                Collective equity
                            </div>
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-blue-300 mr-2"/>
                                Your deposit
                            </div> 
                        </div>
                    </div>
                    <div className="flex w-full">
                        { (coOwned.missingCapital > 0) ?
                        <div className="flex flex-col mx-2 my-6">
                            <p className="text-4xl text-black font-bold text-red-400" data-testid="cypress_alternative_monthlyPayment">-£{coOwned.missingCapital}k</p>
                            <p className="text-xs text-red-400 w-2/3" data-testid="cypress_alternative_missingIncomeInfo">Your income and deposit are too low. To qualify for the full loan or you need to increase your deposit by ${coOwned.missingCapital}k</p>
                        </div>
                        :
                        <div className="flex flex-col mx-2 my-6">
                            <p className="text-4xl text-black font-bold" data-testid="cypress_alternative_monthlyPayment">£{coOwned.rates.averageMonthlyPayments}</p>
                            <p className="text-sm text-gray-600 font-bold">Average monthly spend*</p>
                            <p className="text-xs text-gray-400">*  {coOwned.rates.initialRateMonthsCount} months of £{coOwned.rates.initialMonthlyPaymentsRounded} at {(INITAL_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-gray-400">*  {coOwned.rates.subsequentRateMonthsCount} months of £{coOwned.rates.subsequentMonthlyPaymentsRounded} at {(SUBSEQUENT_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-gray-400 w-2/3">including max £{coOwned.rates.rentPayment} monthly rental payment to equity owners based on Bank of England rates</p>
                        </div>
                        }
                        
                    </div>
                    <div className="flex flex-row  items-center justify-end">
                        <button
                            className=" inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-ocTeal transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
                        >
                            Start your campaign 
                            <svg
                            className="inline-block w-3 ml-2"
                            fill="currentColor"
                            viewBox="0 0 12 12"
                            >
                            <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}