import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";

import { SmallNav } from '../components/header';
import { Footer } from '../components/footer';

import Progress from '../components/calculator/progress';
import Goal from '../components/calculator/goal';
import PropertyValue from '../components/calculator/propertyValue';
import Duration from '../components/calculator/duration';
import Deposit from '../components/calculator/deposit';
import Income from '../components/calculator/income';

import Summary from '../components/calculator/summary';
import Options from '../components/calculator/options';

import bgImg from './../images/couple-on-stairs.png';


const CalculatorPage = () => {

  const [progress, setProgress] = useState(1);
  const [quoteState, setQuoteState] = useState({
    quoteType : null,
    propertyValue : null,
    depositValue : null,
    mortgageDuration : null,
    income : null,
    duration: 25
  });

  const [calculator, showCalculator] = useState(0);

  const setProgressFn = () => setProgress(progress + 1);
  const setQuoteStateFn = (updatedState) => {
    const newQuoteState = {...quoteState, ...updatedState};
    setQuoteState(newQuoteState)
  };

  let calculatorWidth = "w-full lg:w-1/2";
  let calculatorVisible = "invisible opacity-0";
  let stepsVisible = "";
  if(calculator) {
    calculatorWidth = "w-full";
    stepsVisible = "hidden";
    calculatorVisible = "visible opacity-100";
  }

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Owned Collectively - deposit calculator</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href={"https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,300&display=swap"} rel="stylesheet"></link>
    </Helmet>
    <main className="bg-gray-100">
      <title>OC - Deposit calculator</title>
      <SmallNav 
        smallNavVisible={true}
        setIsMenuOpen={()=>{}}
      />
      <div className="flex min-h-screen">
          <div className="">
            <img
              src={bgImg}
              className="absolute inset-0 object-cover w-full h-full"
              alt=""
            />
          </div>
          <div className={`flex flex-col pt-28 pl-8 pr-8 z-10 bg-ocSoftGray items-center transition-width transition-slowest ease duration-500 ${calculatorWidth}`}>
            <div className={`w-full flex flex-col items-center justify-center max-w-lg ${stepsVisible}`}>
              <Progress 
                steps={[1,2,3,4,5]}
                currentStep={progress}
              />
              <div className="flex flex-row flex-nowrap w-full">
                <Goal 
                  setProgress={setProgressFn}
                  setQuoteState={setQuoteStateFn}
                  step={1}
                  currentStep={progress}
                  quoteState={quoteState}
                />
                <PropertyValue
                  setProgress={setProgressFn}
                  step={2}
                  currentStep={progress}
                  setQuoteState={setQuoteStateFn}
                  quoteState={quoteState}
                />
                <Deposit
                  setProgress={setProgressFn}
                  step={3}
                  currentStep={progress}
                  setQuoteState={setQuoteStateFn}
                  quoteState={quoteState}
                />
                <Income
                  setProgress={setProgressFn}
                  step={4}
                  currentStep={progress}
                  setQuoteState={setQuoteStateFn}
                  quoteState={quoteState}
                />
                <Duration
                  showCalculator={showCalculator}
                  setProgress={setProgressFn}
                  step={5}
                  currentStep={progress}
                  setQuoteState={setQuoteStateFn}
                  quoteState={quoteState}
                />
              </div>
            </div>
            <div className={`flex flex-col-reverse lg:flex-row transition duration-300 delay-500 ${calculatorVisible} mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8`}>
                  {calculator && 
                  <>
                    <Summary 
                      quoteState={quoteState}
                      setQuoteState={setQuoteStateFn}
                    />
                    <Options 
                      quoteState={quoteState}
                    />
                  </>
                  }
              </div>
          </div>
      </div>
      <Footer />
    </main>
    </>
  )
}

export default CalculatorPage