import * as actualRates from './utils_rates.js';

const isBrowser = () => typeof window !== "undefined";
let isTest = false;
if (typeof window !== `undefined`) {
    isTest = window.Cypress;
}

const rates = {};

if (isBrowser && isTest) {
    rates.INITAL_INTREST_RATE =  0.0199;
    rates.INITIAL_RATE_DURATION_PERCENTAGE = 0.21;
    rates.SUBSEQUENT_INTREST_RATE = 0.0354;
    rates.RENT_INTREST_RATE = 0.0075;
    rates.MINIMUM_DEPOSIT = 0.05;
    rates.BORROWING_LIMIT_ON_INCOME = 4.5;
} else {
    rates.INITAL_INTREST_RATE =  actualRates.INITAL_INTREST_RATE;
    rates.INITIAL_RATE_DURATION_PERCENTAGE = actualRates.INITIAL_RATE_DURATION_PERCENTAGE;
    rates.SUBSEQUENT_INTREST_RATE = actualRates.SUBSEQUENT_INTREST_RATE;
    rates.RENT_INTREST_RATE = actualRates.RENT_INTREST_RATE;
    rates.MINIMUM_DEPOSIT = actualRates.MINIMUM_DEPOSIT;
    rates.BORROWING_LIMIT_ON_INCOME = actualRates.BORROWING_LIMIT_ON_INCOME;
}

const {
    INITAL_INTREST_RATE,
    INITIAL_RATE_DURATION_PERCENTAGE,
    SUBSEQUENT_INTREST_RATE,
    RENT_INTREST_RATE,
    MINIMUM_DEPOSIT,
    BORROWING_LIMIT_ON_INCOME
} = rates;

const DISPLAY_NUMBER = 1000;

function PMT(ir, np, pv, fv, type) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
        return -(pv + fv)/np;

    pvif = Math.pow(1 + ir, np);
    pmt = - ir * (pv * pvif + fv) / (pvif - 1);

    if (type === 1)
        pmt /= (1 + ir);

    return pmt;
}

const calculateRates = function(loanLength, loanAmount, collectiveAmount) {
    
    const loanLengthInMonths = loanLength*12;
    const initialRateMonthsCount = Math.round(loanLengthInMonths*INITIAL_RATE_DURATION_PERCENTAGE);
    const subsequentRateMonthsCount = Math.round(loanLengthInMonths*(1-INITIAL_RATE_DURATION_PERCENTAGE));
    const initialIntrestRatePerMonth = INITAL_INTREST_RATE/12;
    const subsequentIntrestRatePerMonth = SUBSEQUENT_INTREST_RATE/12;
    const initialMonthlyPayments = PMT(initialIntrestRatePerMonth, loanLengthInMonths, loanAmount).toFixed(2);
    const subsequentMonthlyPayments = PMT(subsequentIntrestRatePerMonth, loanLengthInMonths, loanAmount).toFixed(2);

    const initialMonthlyPaymentsRounded = -1*Math.round(initialMonthlyPayments);
    const subsequentMonthlyPaymentsRounded = -1*Math.round(subsequentMonthlyPayments);

    const averageMonthlyPaymentsWithoutRent = -1*Math.round(((initialMonthlyPayments*initialRateMonthsCount)+(subsequentMonthlyPayments*subsequentRateMonthsCount))/loanLengthInMonths);
    let averageMonthlyPaymentsWithRent = averageMonthlyPaymentsWithoutRent;
    let rentPayment = 0;

    if(collectiveAmount) {
        rentPayment = Math.round(collectiveAmount*RENT_INTREST_RATE/12);
        averageMonthlyPaymentsWithRent += rentPayment;
    }

    return {
        initialMonthlyPaymentsRounded,
        initialRateMonthsCount,
        subsequentMonthlyPaymentsRounded,
        subsequentRateMonthsCount,
        averageMonthlyPayments : Math.round(averageMonthlyPaymentsWithRent),
        rentPayment
    }
}

export const calculateSoloPurchase = function(quoteData) {
    
    const {
        propertyValue,
        depositValue,
        income
    } = quoteData;

    let missingCapital = 0;
    let bankLoan = propertyValue-depositValue;
    let missingIncome = 0;

    const maxBankLoan = income*BORROWING_LIMIT_ON_INCOME;
    const minimumDeposit = propertyValue*MINIMUM_DEPOSIT;

    const depositIsTooSmall = minimumDeposit > depositValue;
    const maxLoanBasedOnIncomeIsTooSmall = bankLoan > maxBankLoan;

    console.log(maxBankLoan, minimumDeposit, depositIsTooSmall, maxLoanBasedOnIncomeIsTooSmall);

    if(maxLoanBasedOnIncomeIsTooSmall) {

        missingCapital += bankLoan-maxBankLoan;
        bankLoan = maxBankLoan;
        missingIncome = missingCapital/4.5;

    } else if(depositIsTooSmall) {

        missingCapital = minimumDeposit-depositValue;
        bankLoan -= missingCapital;

    }

    //case 1 = your deposit is too small -> Increase deposit & reduce bankLoan
    //case 2 = the amount you can borrow is too small -> Increase deposit
    //case 3 = the amount you can borrow is enough but your deposit is too small -> Increase deposit
    //case 4 = your deposit is enough but the amount you can borrow is too small -> Increase deposit
    
    return {
        bankLoan : Math.round(bankLoan/DISPLAY_NUMBER),
        missingCapital : Math.round(missingCapital/DISPLAY_NUMBER),
        deposit : Math.round(depositValue/DISPLAY_NUMBER),
        bankLoanHeight : Math.round((bankLoan/propertyValue)*100),
        missingCapitalHeight : Math.round((missingCapital/propertyValue)*100),
        depositHeight : Math.round((depositValue/propertyValue)*100),
        rates : calculateRates(quoteData.duration, bankLoan+missingCapital),
        missingIncome : Math.round(missingIncome/DISPLAY_NUMBER)

    };
}

export const calculateCollective = function(quoteData, sharedEquity) {

    const {
        propertyValue,
        depositValue,
        income
    } = quoteData;

    const sharedEquityAmount = Math.round(propertyValue * (sharedEquity/100));
    const maxBankLoan = income*BORROWING_LIMIT_ON_INCOME;
    const minimumDeposit = propertyValue*MINIMUM_DEPOSIT;

    let missingCapital = 0;
    let bankLoan = propertyValue-(depositValue+sharedEquityAmount);

    const depositIsTooSmall = minimumDeposit > (depositValue+sharedEquityAmount);
    const maxLoanBasedOnIncomeIsTooSmall = bankLoan > maxBankLoan;

    if(maxLoanBasedOnIncomeIsTooSmall) {

        missingCapital += bankLoan-maxBankLoan;
        bankLoan = maxBankLoan;

    } else if(depositIsTooSmall) {

        missingCapital = minimumDeposit-(depositValue+sharedEquityAmount);
        bankLoan -= missingCapital;

    }

    return {
        bankLoan : Math.round(bankLoan/DISPLAY_NUMBER),
        sharedEquityAmount : Math.round(sharedEquityAmount/DISPLAY_NUMBER),
        missingCapital : Math.round(missingCapital/DISPLAY_NUMBER),
        deposit :  Math.round(depositValue/DISPLAY_NUMBER),
        bankLoanHeight : Math.round((bankLoan/propertyValue)*100),
        missingCapitalHeight : Math.round((missingCapital/propertyValue)*100),
        depositHeight : Math.round((depositValue/propertyValue)*100),
        sharedEquityHeight : Math.round((sharedEquityAmount/propertyValue)*100),
        rates : calculateRates(quoteData.duration, bankLoan, sharedEquityAmount)
    };
}

export const getMinimumCollectiveShare = function(quoteData) {
    const {
        propertyValue,
        depositValue,
        income
    } = quoteData;

    const maxBankLoan = income*BORROWING_LIMIT_ON_INCOME;

    const neededBankLoan = propertyValue - depositValue;

    if(maxBankLoan > neededBankLoan) {
        const minimumOCShare = 0;
        return minimumOCShare;
    } else {
        const minimumOCAmount = propertyValue - depositValue - maxBankLoan;
        const minimumOCShare = Math.round((minimumOCAmount/propertyValue)*100);
        return minimumOCShare;
    }
}