import React, { useState } from 'react';

export default function Summary (props) {

    const [editPropertyValue, setEditPropertyValue] = useState(false);
    const handleEditPropertyValue = () => {
        setEditPropertyValue(!editPropertyValue);
    }

    const [editDeposit, setEditDeposit] = useState(false);
    const handleEditDeposit = () => {
        setEditDeposit(!editDeposit);
    }

    const [editIncome, setEditIncome] = useState(false);
    const handleEditIncome = () => {
        setEditIncome(!editIncome);
    }

    const updatePropertyPrice = (e) => {
        const value = Number(e.target.value.replace(/[^\d\.\-]/g, ""));
        props.setQuoteState({
            propertyValue : Number(value),
        });
    }

    const updateDeposit = (e) => {
        const value = Number(e.target.value.replace(/[^\d\.\-]/g, ""));
        props.setQuoteState({
            depositValue : Number(value),
        });
    }

    const updateIncome = (e) => {
        const value = Number(e.target.value.replace(/[^\d\.\-]/g, ""));
        props.setQuoteState({
            income : value,
        });
    }

    return (
        <div className={`w-full lg:w-1/4 my-4 lg:my-0`}>
            <div className="flex flex-col justify-center lg:justify-start items-start flex-wrap">
                <h3 className="pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocBlue">My 🏡 cost&nbsp;</h3>
                {editPropertyValue ? 
                     <input 
                        type="number"
                        value={props.quoteState.propertyValue}
                        onChange={updatePropertyPrice}
                        onBlur={handleEditPropertyValue } 
                        className="bg-transparent p-2 pb-0 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocTeal border-2 border-ocTeal border-dashed w-1/2" 
                    />
                :
                <h3 
                    className="pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocTeal border-b-2 border-ocTeal border-dashed cursor-pointer"
                    onClick={handleEditPropertyValue}
                >£{new Intl.NumberFormat().format(props.quoteState.propertyValue)}.</h3>
                }
                <h3 className="mt-8 pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocBlue">I have a 💰 of</h3>
                {editDeposit ? 
                     <input 
                        type="number"
                        value={props.quoteState.depositValue}
                        onChange={updateDeposit}
                        onBlur={handleEditDeposit } 
                        className="bg-transparent p-2 pb-0 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocTeal border-2 border-ocTeal border-dashed w-1/2" 
                    />
                :
                <h3 
                    className="pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocTeal border-b-2 border-ocTeal border-dashed cursor-pointer"
                    onClick={handleEditDeposit}
                >£{new Intl.NumberFormat().format(props.quoteState.depositValue)}.</h3>
                }
                <h3 className="pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocBlue">and want a</h3>
                <h3 className="pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocTeal border-b-2 border-ocTeal border-dashed cursor-pointer">{props.quoteState.duration} year</h3>
                <h3 className="pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocBlue">mortgage 📅.</h3>
                <h3 className="mt-8 pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocBlue">My yearly<br />income 💸 is</h3>
                {editIncome ? 
                     <input 
                        type="number"
                        value={props.quoteState.income}
                        onChange={updateIncome}
                        onBlur={handleEditIncome} 
                        className="bg-transparent p-2 pb-0 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocTeal border-2 border-ocTeal border-dashed w-1/2" 
                    />
                :
                <h3 
                    className="pt-2 text-2xl lg:text-3xl tracking-widest font-ocTheme font-semibold text-ocTeal border-b-2 border-ocTeal border-dashed cursor-pointer"
                    onClick={handleEditIncome}
                >£{new Intl.NumberFormat().format(props.quoteState.income)}.</h3>
                }
            </div>
        </div>
    )
}