import React, { useState } from 'react';

const piggyBank = (<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><rect fill="none" height="24" width="24"/><g><path d="M15,10c0-0.55,0.45-1,1-1s1,0.45,1,1c0,0.55-0.45,1-1,1S15,10.55,15,10z M8,9h5V7H8V9z M22,7.5v6.97l-2.82,0.94L17.5,21 L12,21v-2h-2v2l-5.5,0C4.5,21,2,12.54,2,9.5S4.46,4,7.5,4l5,0c0.91-1.21,2.36-2,4-2C17.33,2,18,2.67,18,3.5 c0,0.21-0.04,0.4-0.12,0.58c-0.14,0.34-0.26,0.73-0.32,1.15l2.27,2.27H22z M20,9.5h-1L15.5,6c0-0.65,0.09-1.29,0.26-1.91 C14.79,4.34,14,5.06,13.67,6L7.5,6C5.57,6,4,7.57,4,9.5c0,1.88,1.22,6.65,2.01,9.5L8,19v-2h6v2l2.01,0l1.55-5.15L20,13.03V9.5z"/></g></svg>);
const house = (<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><rect fill="none" height="24" width="24"/><path d="M12,3L6,7.58V6H4v3.11L1,11.4l1.21,1.59L4,11.62V21h16v-9.38l1.79,1.36L23,11.4L12,3z M18,19h-5v-4h-2v4H6v-8.9l6-4.58 l6,4.58V19z M10,1c0,1.66-1.34,3-3,3C6.45,4,6,4.45,6,5H4c0-1.66,1.34-3,3-3c0.55,0,1-0.45,1-1H10z"/></svg>);
// markup

export default function Goal (props) {

    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const setNextButtonDisabledFn = () => setNextButtonDisabled(false);

    const nextScreen = (e) => {
        e.preventDefault();
        props.setProgress();
    }

    const handleSelectAffordability = (e) => {
        props.setQuoteState({
            quoteType : "affordability",
        });
        setNextButtonDisabledFn();
    };

    const handleSelectStart = (e) => {
        props.setQuoteState({
            quoteType : "start",
        });
        setNextButtonDisabledFn();
    };

    const isVisible = props.currentStep === props.step ? "opacity-100" : "opacity-0 hidden";
    let nextCSS = "mt-4 inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide rounded-full transition-colors duration-500 "
    if(nextButtonDisabled) {
        nextCSS += "text-gray-400 bg-gray-200";
    } else {
        nextCSS += "text-ocTeal hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2";
    }

    let affordabilityCheckCss = "w-1/2 h-40 p-8 flex flex-col items-center justify-center rounded shadow-md border-2 border-gray hover:border-ocTeal mr-1 lg:mr-4 cursor-pointer";

    if(props.quoteState.quoteType === "affordability") {

        affordabilityCheckCss = "w-1/2 h-40 p-8 flex flex-col items-center justify-center rounded shadow-md border-2 border-ocTeal mr-1 lg:mr-4 cursor-pointer bg-ocTeal text-white";

    }

    let startCss = "w-1/2 h-40 p-8 flex flex-col items-center justify-center rounded shadow-md border-2 border-gray hover:border-ocTeal mr-1 lg:mr-4 cursor-pointer";

    if(props.quoteState.quoteType === "start") {

        startCss = "w-1/2 h-40 p-8 flex flex-col items-center justify-center rounded shadow-md border-2 border-ocTeal mr-1 lg:mr-4 cursor-pointer bg-ocTeal text-white";

    }


    
    return (
        <div className={`w-full transition ${isVisible}`}>
            <div className="flex flex-col items-center mt-8">
            <h1 className="pt-2 text-2xl lg:text-4xl font-semibold text-ocBlue">🙋‍ How can we help? </h1>
            <h2 className="text-md text-gray-400">
                Select the option that best fits you. 
            </h2>
            </div>
            <div className="flex flex-row mt-8">
                <div 
                    className={affordabilityCheckCss}
                    onClick={handleSelectAffordability}
                    data-testid="cypress_remortgage"
                >
                    <div className="rounded-full bg-ocLightCyan p-2 mb-2 text-white">{piggyBank}</div>
                    <h3 className="text-center font-light">Explore remortgaging</h3>
                </div>
                <div 
                    className={startCss}
                    onClick={handleSelectStart}
                    data-testid="cypress_newMortgage"
                >
                    <div className="rounded-full bg-ocLightCyan p-2 mb-2 text-white">{house}</div>
                    <h3 className="text-center font-light">Check what I can afford</h3>
                </div>
            </div>
            <div className="flex flex-row  items-center justify-end mt-8">
            <button
                className={nextCSS}
                onClick={nextScreen}
                disabled={nextButtonDisabled}
                data-testid="cypress_next"
            >
                Next
                <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
                >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
            </button>
            </div>

        </div>
    )
}