import React from 'react';

export default function Duration (props) {

    const handleSelectTenYear = (e) => {
        props.setQuoteState({
            duration : 10,
        });
        //setNextButtonDisabledFn();
    };

    const handleSelectFifteenYear = (e) => {
        props.setQuoteState({
            duration : 15,
        });
        //setNextButtonDisabledFn();
    };

    const handleSelectTwentyFiveYear = (e) => {
        props.setQuoteState({
            duration : 25,
        });
        //setNextButtonDisabledFn();
    };

    const nextScreen = (e) => {
        e.preventDefault();
        props.showCalculator(1);
        props.setProgress();
    }
    const isVisible = props.currentStep === props.step ? "opacity-100" : "opacity-0 hidden";
    const unselectedCSS = "w-1/3 h-40 p-8 flex flex-col items-center justify-center rounded shadow-md border-2 border-gray hover:border-ocTeal mr-1 lg:mr-4 cursor-pointer";
    const selectedCSS = "w-1/3 h-40 p-8 flex flex-col items-center justify-center rounded shadow-md border-2 border-ocTeal mr-1 lg:mr-4 cursor-pointer bg-ocTeal text-white";

    let tenYearCss = unselectedCSS;
    let fifteenYearCSS = unselectedCSS;
    let twentyFiveYearCSS = unselectedCSS;

    if(props.quoteState.duration === 10) {

        tenYearCss = selectedCSS;

    } else if (props.quoteState.duration === 15) {

        fifteenYearCSS = selectedCSS;

    } else if (props.quoteState.duration === 25) {

        twentyFiveYearCSS = selectedCSS;

    }

    return (
        <div className={`w-full transition ${isVisible}`}>
            <div className="flex flex-col items-center mt-8">
            <h1 className="pt-2 text-2xl lg:text-4xl font-semibold text-ocBlue">🗓️ How long? </h1>
            <h2 className="text-md text-gray-400">
                Over what time period do you want to pay off the balance?
            </h2>
            </div>
            <div className="flex flex-row mt-8">
                <div 
                    className={tenYearCss}
                    onClick={handleSelectTenYear}
                >
                    <div className="rounded-full w-10 bg-ocLightCyan p-2 mb-2 text-sm text-white text-center">10</div>
                    <h3 className="text-center font-light">Year mortgage</h3>
                </div>
                <div 
                    className={fifteenYearCSS}
                    onClick={handleSelectFifteenYear}
                >
                    <div className="rounded-full w-10 bg-ocLightCyan p-2 mb-2 text-sm text-white text-center">15</div>
                    <h3 className="text-center font-light">Year mortgage</h3>
                </div>
                <div 
                    className={twentyFiveYearCSS}
                    onClick={handleSelectTwentyFiveYear}
                    data-testid="cypress_duration25"
                >
                    <div className="rounded-full w-10 bg-ocLightCyan p-2 mb-2 text-sm text-white text-center">25</div>
                    <h3 className="text-center font-light">Year mortgage</h3>
                </div>
            </div>
            <div className="flex flex-row  items-center justify-end mt-8">
            <a
                href="#"
                aria-label=""
                className="mt-4 inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-ocTeal transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
                onClick={nextScreen}
                data-testid="cypress_duration_next"
            >
                Calculate
                <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
                >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
            </a>
            </div>

        </div>
    )
}