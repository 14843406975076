import React from 'react';

export default function Progress (props) {
    
    const steps = props.steps.map(number => {
        let cssClasses = "flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 text-sm text-white";
        if(props.currentStep === number) {
            cssClasses = "flex items-center justify-center w-8 h-8 rounded-full bg-ocTeal text-sm text-white";
        }
        return ( <div key={"step_"+number} className={cssClasses}>{number}</div>);
    });
    return (
        <div className="w-full flex flex-row items-center justify-between mb-8 bg-backgroundLine bg-repeat-x bg-center">
           {steps}
        </div>
    );
}