import React from 'react';


export default function PropertyValue (props) {

    const nextScreen = (e) => {
        e.preventDefault();
        props.setProgress();
    }

    const updatePropertyPrice = (e) => {
        const value = Number(e.target.value.replace(/[^\d\.\-]/g, ""));
        props.setQuoteState({
            propertyValue : Number(value)
        });
    }

    const isVisible = props.currentStep === props.step ? "opacity-100" : "opacity-0 hidden";

    const inputValue = props.quoteState.propertyValue ? new Intl.NumberFormat().format(props.quoteState.propertyValue) : '';

    return (
        <div className={`w-full transition ${isVisible}`}>
            <div className="flex flex-col items-center mt-8">
            <h1 className="pt-2 text-2xl lg:text-4xl font-semibold text-ocBlue">💰 How much is it worth? </h1>
            <h2 className="text-md text-gray-400">
                Enter the value of the property
            </h2>
            </div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="relative flex w-40 flex-wrap items-stretch mb-3">
                    <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-lg items-center justify-center w-8 pl-3 py-4">
                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="currentcolor"><g><rect fill="none" height="24" width="24"/></g><g><path d="M14,21c1.93,0,3.62-1.17,4-3l-1.75-0.88C16,18.21,15.33,19,14,19l-4.9,0c0.83-1,1.5-2.34,1.5-4c0-0.35-0.03-0.69-0.08-1 L14,14v-2l-4.18,0C9,10.42,8,9.6,8,8c0-1.93,1.57-3.5,3.5-3.5c1.5,0,2.79,0.95,3.28,2.28L16.63,6c-0.8-2.05-2.79-3.5-5.13-3.5 C8.46,2.5,6,4.96,6,8c0,1.78,0.79,2.9,1.49,4L6,12v2l2.47,0c0.08,0.31,0.13,0.64,0.13,1c0,2.7-2.6,4-2.6,4v2H14z"/></g></svg>
                    </span>
                    <input 
                        type="text"
                        value={inputValue} 
                        placeholder="499,950" 
                        className="px-3 py-4 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-xl border-0 shadow outline-none focus:outline-none focus:ring w-full pl-10"
                        onChange={updatePropertyPrice}
                        data-testid="cypress_propertyValue"
                    />
                </div>

            </div>
            <div className="flex flex-row  items-center justify-end mt-8">
            <a
                href="#"
                aria-label=""
                className="mt-4 inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-ocTeal transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
                onClick={nextScreen}
                data-testid="cypress_propertyValue_next"
            >
                Next
                <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
                >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
            </a>
            </div>

        </div>
    )
}